<template>
  <table>
    <tr class="name">
      <td>Validity</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>Not Before</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>Not After</td>
      <td></td>
    </tr>
    <tr class="name">
      <td>Subject</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>countryName</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>organizationName</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>organizationalUnitName</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>commonName</td>
      <td></td>
    </tr>
    <tr class="name">
      <td>X509v3 extensions:</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>X509v3 Key Usage</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>X509v3 Basic Constraints</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>X509v3 Subject Key Identifier</td>
      <td></td>
    </tr>
    <tr class="sub">
      <td></td>
      <td>X509v3 Certificate Policies</td>
      <td></td>
    </tr>
  </table>
</template>

<style lang="scss" scoped>
td {
  padding: 0.5rem 0.75rem;
}
.name {
  td {
    font-weight: bold;
  }
}
.sub {
  td {
    &:ntd-of-type(1) {
      width: 15%;
    }
    &:ntd-of-type(2) {
      width: 60%;
    }
    &:ntd-of-type(3) {
      width: 25%;
    }
  }
}
</style>